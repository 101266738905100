import { useForm, useFormField } from '@kaliber/forms'
import { optional } from '@kaliber/forms/validation'
import { useElementSize } from '@kaliber/use-element-size'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ensureArray } from '/machinery/ensureArray'
import { useTranslate } from '/machinery/I18n'
import { useOnFormFieldChange } from '/machinery/useOnFormFieldChange'
import { trackFilterInteraction } from '/machinery/tracking/trackFilterInteraction'
import { ButtonSecondary } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'

import media from '/cssGlobal/media.css'
import styles from './SearchAndFilters.css'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'
import iconCross from '/images/icons/cross.raw.svg'
import iconFilter from '/images/icons/filter.raw.svg'
import iconSearch from '/images/icons/search.raw.svg'

export function SearchAndFilters({ tagsOptions, typesOptions }) {
  const { __ } = useTranslate()
  const [active, setActive] = React.useState(true)
  const [{ search = '', tags: rawTags, types: rawTypes }, setQueryString] = useQueryString()

  const tags = ensureArray(rawTags)
  const types = ensureArray(rawTypes)

  const { form: { fields }, submit } = useForm({
    initialValues: {
      search,
      tags,
      types,
    },
    fields: {
      search: optional,
      tags: optional,
      types: optional,
    },
    onSubmit: handleSubmit,
    formId: 'searchForm'
  })

  useOnFormFieldChange(fields.tags, handleTagsChange)
  useOnFormFieldChange(fields.types, handleTypesChange)

  return (
    <form className={styles.component} onSubmit={submit}>
      <Header onActiveChange={handleActiveChange} {...{ active }} />
      <Collapsible {...{ active }}>
        <SearchFieldset
          field={fields.search}
          defaultValue={search}
          layoutClassName={styles.searchFieldset}
          onBlur={handleSearchBlur}
        />
        {Boolean(tagsOptions?.length) && (
          <CollapsibleCheckboxFieldset
            field={fields.tags}
            items={tagsOptions}
            label={__`filter-tag`}
            layoutClassName={styles.collapsibleCheckboxFieldset}
          />
        )}
        {Boolean(typesOptions?.length) && (
          <CollapsibleCheckboxFieldset
            field={fields.types}
            items={typesOptions}
            label={__`filter-type`}
            layoutClassName={styles.collapsibleCheckboxFieldset}
          />
        )}
        <Footer onClickApply={() => setActive(false)} onClickReset={handleReset} />
      </Collapsible>
    </form>
  )

  function handleActiveChange() {
    setActive(x => !x)
  }

  function handleSubmit({ value }) {
    setQueryString(x => ({ ...x, page: null, search: value.search }))
  }

  function handleSearchBlur(e) {
    setQueryString(x => ({ ...x, page: null, search: e.currentTarget.value }))
    trackFilterInteraction({ searchterm: e.currentTarget.value })
  }

  function handleTagsChange(tags) {
    setQueryString(x => ({ ...x, page: null, tags }))
    trackFilterInteraction({ topic: tags })
  }

  function handleTypesChange(types) {
    setQueryString(x => ({ ...x, page: null, types }))
    trackFilterInteraction({ type: types })
  }

  function handleReset() {
    setQueryString(x => ({ ...x, page: null, search: '', tags: [], types: [] }))
  }
}

function Header({ active, onActiveChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentHeader}>
      <button type='button' className={styles.headerSm} onClick={onActiveChange} data-x={active ? 'close-filter' : 'open-filter'}>
        {__`filter`}

        {active
          ? <Icon icon={iconCross} layoutClassName={styles.iconCross} />
          : <Icon icon={iconFilter} layoutClassName={styles.iconFilter} />
        }
      </button>
      <div className={styles.headerMd}>
        {__`filter`}
      </div>
    </div>
  )
}

function Collapsible({ active, children }) {
  const { size: { height }, ref: elementRef } = useElementSize()

  return (
    <div
      className={styles.componentCollapsible}
      style={{ height: active ? height + 'px' : '0px' }}
      aria-hidden={!active}
    >
      <div className={styles.inner} ref={elementRef}>
        {children}
      </div>
    </div>
  )
}

function SearchFieldset({ field, defaultValue, onBlur, layoutClassName }) {
  const { __ } = useTranslate()
  const { name, eventHandlers } = useFormField(field)
  const { onFocus, onChange } = eventHandlers

  return (
    <fieldset className={cx(styles.componentSearchFieldset, layoutClassName)}>
      <input
        id={name}
        className={styles.input}
        type='text'
        placeholder={__`search-placeholder`}
        onBlur={(e) => {
          eventHandlers.onBlur(e)
          onBlur(e)
        }}
        {...{ name, defaultValue, onFocus, onChange }}
      />
      <Icon icon={iconSearch} label={__`search`} layoutClassName={styles.iconSearch} />
    </fieldset>
  )
}

function CollapsibleCheckboxFieldset({ field, label, items, layoutClassName }) {
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)
  const [active, setActive] = React.useState(isMd)

  React.useEffect(
    () => {
      isMd && setActive(true)
    },
    [isMd]
  )

  return (
    <fieldset className={cx(styles.componentCollapsibleCheckboxFieldset, active && styles.isActive, layoutClassName)}>
      {isMd
        ? <div className={cx(styles.legend, styles.legendMd)}>{label}</div>
        : (
          <button type='button' className={cx(styles.legend, styles.legendSm)} onClick={handleActiveChange} data-x={active ? 'close-filter-options' : 'open-filter-options'}>
            {label}
            <Icon icon={iconChevronDown} layoutClassName={cx(styles.icon, active && styles.isFlipped)} />
          </button>
        )
      }

      <Collapsible {...{ active }}>
        {active && items?.map((x, i) => {
          const value = x?.slug?.current || x?.value

          return (
            <Checkbox key={i} instanceValue={value} {...{ field }}>
              {x?.title}
            </Checkbox>
          )
        })}
      </Collapsible>
    </fieldset>
  )

  function handleActiveChange() {
    setActive(x => !x)
  }
}

function Checkbox({ field, instanceValue, children }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value } = state

  return (
    <label className={styles.componentCheckbox}>
      <input
        value={instanceValue}
        checked={[].concat(value).includes(instanceValue)}
        type='checkbox'
        onChange={handleChange}
        {...{ name }}
        {...eventHandlers}
      />
      {children}
    </label>
  )

  function handleChange(e) {
    if (e.target.checked) {
      // Make sure this value is included in the value array
      onChange([].concat(value).includes(e.target.value) ? value : value.concat(e.target.value))
    } else {
      onChange([].concat(value).filter(x => x !== e.target.value))
    }
  }
}

function Footer({ onClickApply, onClickReset }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFooter}>
      <ButtonSecondary
        layoutClassName={styles.apply}
        onClick={onClickApply}
        dataX='filter-apply'
      >
        {__`filter-apply`}
      </ButtonSecondary>
      <button
        type='reset'
        className={styles.reset}
        data-x='filter-reset'
        onClick={onClickReset}
      >
        {__`filter-reset`}
      </button>
    </div>
  )
}
