import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { OverviewItemInspiration, OverviewItemJob } from '/features/buildingBlocks/OverviewItem'
import { Pagination } from '/features/buildingBlocks/Pagination'
import { routeMap } from '/routeMap'

import styles from './OverviewGrid.css'

export function OverviewGridJobs({ items, layoutClassName }) {
  return (
    <OverviewGridBase className={cx(styles.componentJobs, layoutClassName)} >
      {items?.map(x => {
        const href = determineDocumentPathSync({ document: x, routeMap })

        return (
          <div key={x._key} className={styles.item}>
            <OverviewItemJob {...{ href }} {...x} />
          </div>
        )
      })}
    </OverviewGridBase>
  )
}

export function OverviewGridInspirations({ items, pagination, layoutClassName }) {
  return (
    <OverviewGridBase className={cx(styles.componentInspirations, layoutClassName)} {...{ pagination }}>
      {items?.map(x => {
        const href = determineDocumentPathSync({ document: x, routeMap })

        return (
          <div key={x._id} className={styles.item}>
            <OverviewItemInspiration {...{ href }} {...x} />
          </div>
        )
      })}
      {pagination?.maxPages > 1 && pagination?.currentPage && (
        <ContainerMd>
          <Pagination
            maxPages={pagination.maxPages}
            currentPage={pagination.currentPage}
            layoutClassName={styles.pagination}
          />
        </ContainerMd>
      )}
    </OverviewGridBase>
  )
}

function OverviewGridBase({ children, className }) {
  return (
    <section data-x='overview-grid' {...{ className }}>
      <div className={styles.componentBase}>
        {children}
      </div>
    </section>
  )
}
