export async function handleResponse(response) {
  const text = await response.text()
  if (!response.ok && response.status !== 422) throwUnexpectedResponse(response.status, text)
  if (text === '') return null

  try {
    const data = JSON.parse(text)
    if (data.error) throw new Error(data.error)
    return data
  } catch (e) {
    throwUnexpectedResponse(response.status, text)
  }
}

function throwUnexpectedResponse(status, text) {
  throw new Error(`Unexpected response ${status}: ${text}`)
}
