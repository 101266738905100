import { useLanguage, useTranslate } from '/machinery/I18n'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { useClientConfig } from '/machinery/ClientConfig'
import { useQuery } from '@tanstack/react-query'
import { RESULTS_PER_PAGE, routeMap } from '/routeMap'
import { handleResponse } from '/machinery/handleResponse'
import { generateEsRequest } from '/features/queries/queries'
import { parsePageNumber } from '/machinery/parsePageNumber'
import { OverviewGridInspirations } from '/features/pageOnly/OverviewGrid'
import { SearchAndFilters } from '/features/buildingBlocks/SearchAndFilters'
import { reportClientError } from '/machinery/reportClientError'

import styles from './InspirationsOverviewAndFilter.css'

export function InspirationsOverviewAndFilter({ inspirationTags, initialData }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const [{ search = '', tags: rawTags, types: rawTypes, page = 1 }] = useQueryString()
  const { searchMappings } = useClientConfig()
  const mappings = [searchMappings.inspiration.default]
  const placeholderDataRef = React.useRef(initialData)

  const { isSuccess, isLoading, data: searchResults } = useQuery({
    queryKey: [routeMap.api.v1.search(), { search, mappings, language, rawTypes, rawTags, page }],
    queryFn: () => searchInEs({ search, mappings, language, rawTypes, rawTags, page }, { placeholderDataRef }),
    refetchOnWindowFocus: false,
    placeholderData: placeholderDataRef.current ? placeholderDataRef.current : {}
  })

  const foundArticles = React.useMemo(() => extractArticles(searchResults), [searchResults])
  const pagination = React.useMemo(
    () => ({
      maxPages: Math.ceil(extractHits(searchResults) / RESULTS_PER_PAGE),
      currentPage: parsePageNumber(page)
    }),
    [searchResults, page]
  )

  const articlesFound = Boolean(isSuccess && foundArticles?.length)
  const tags = inspirationTags?.filter(x => x.count) || []

  // Note: the types should correspond with the types in:
  //  /admin/schemas/fields/typeInspiration.js
  const types = [
    { title: __`type-article`, value: 'article' },
    { title: __`type-businesscase`, value: 'businesscase' },
    { title: __`type-ebook`, value: 'ebook' },
    { title: __`type-podcast`, value: 'podcast' },
    { title: __`type-video`, value: 'video' },
  ]

  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        <aside className={styles.filters}>
          <SearchAndFilters tagsOptions={tags} typesOptions={types} />
        </aside>
        {isLoading && <>{__`search-loading`}</>}
        {!articlesFound && <>{__`search-no-results`}</>}
        {articlesFound && <OverviewGridInspirations items={foundArticles} layoutClassName={styles.overviewGrid} {...{ pagination }} />}
      </div>
    </div>
  )
}

async function searchInEs({ search, language, mappings, rawTypes, rawTags, page }, { placeholderDataRef }) {
  try {

    const response = await fetch(
      routeMap.api.v1.search(),
      {
        method: 'POST',
        body: JSON.stringify({
          request: generateEsRequest({ searchString: search, page, tags: rawTags, types: rawTypes }),
          language,
          mappings
        }),
        headers: { 'Content-Type': 'application/json' }
      })

    const handledResponse = await handleResponse(response)
    placeholderDataRef.current = handledResponse
    return handledResponse
  } catch (e) {
    reportClientError(e)
    return {}
  }
}

function extractArticles(elasticsearchResults) {
  return elasticsearchResults?.hits?.hits.map(x => ({ ...x._source, _type: x._source.type, _id: x._id })) || []
}

function extractHits(elasticsearchResults) {
  return elasticsearchResults?.hits?.total?.value || 0
}
