import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ReadTime.css'

import eyeIcon from '/images/icons/eye.raw.svg'

export function ReadTime({ time }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <Icon icon={eyeIcon} />
      <span>{time} {__`readtime-minutes`}</span>
    </div>
  )
}
