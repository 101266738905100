import { track } from '/machinery/track'

export function trackFilterInteraction({ type, topic, searchterm  }) {
  const DELIMITER = '||'
  return (
    track({ event: 'filtered',
      metadata: {
        user: {
          filters: {
            type: type?.sort()?.join(DELIMITER) ?? type,
            topic: topic?.sort()?.join(DELIMITER) ?? topic,
            searchterm,
            lastchanged: type ? 'type' : topic ? 'topic' : searchterm ? 'searchterm' : '',
          }
        }
      }
    })
  )
}
