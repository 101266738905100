import { matchAll, search, or, and, filter, term } from '@kaliber/elasticsearch/query'
import { ensureArray } from '/machinery/ensureArray'
import { parsePageNumber } from '/machinery/parsePageNumber'
import { RESULTS_PER_PAGE } from '/routeMap'
import { ensureString } from '/machinery/ensureString'

export function generateEsRequest({ searchString, page, tags, types }) {
  const parsedSearchString = ensureString(searchString)
  const parsedTags = ensureArray(tags)
  const parsedTypes = ensureArray(types)
  const from = (parsePageNumber(page) - 1) * RESULTS_PER_PAGE

  const sort = getSortQuery({ searchString: parsedSearchString, types: parsedTypes, tags: parsedTags })
  const query = createQuery({ searchString: parsedSearchString, types: parsedTypes, tags: parsedTags })

  const size = RESULTS_PER_PAGE

  return { from, sort, query, size }
}

function createQuery({ searchString, types, tags }) {
  const showAll = !searchString && !types.length && !tags.length

  return showAll
    ? matchAll()
    : and(
      or(
        search(['title'], searchString),
        search(['intro'], searchString),
        search(['content'], searchString),
      ),
      or(...(types.map(x => filter(term('typeInspiration', x))) || [])),
      or(...(tags.map(x => filter(term('tags.slug', x))) || []))
    )
}

function getSortQuery({ searchString, tags, types }) {
  const showAll = !searchString && !types.length && !tags.length
  const sort = showAll ? [{ publicationDate: { order: 'desc' } }] : ['_score', { publicationDate: { order: 'desc' } }]
  return sort
}
