import { LocationProvider } from '@kaliber/routing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { InspirationsOverviewAndFilter } from './InspirationsOverviewAndFilter'
import { routeMap } from '/routeMap'

const client = new QueryClient()
export default function InspirationsOverviewAndFilterUniversal({ inspirationTags, location, initialData }) {
  return (
    <QueryClientProvider {...{ client }}>
      <LocationProvider initialLocation={location} {...{ routeMap }} >
        <InspirationsOverviewAndFilter {...{  inspirationTags, initialData }} />
      </LocationProvider>
    </QueryClientProvider>
  )
}
