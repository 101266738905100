import { useTranslate } from '/machinery/I18n'
import { ButtonIconSmall } from '/features/buildingBlocks/Button'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Link } from '/features/buildingBlocks/Link'
import { ReadTime } from '/features/buildingBlocks/ReadTime'
import { TextMd } from '/features/buildingBlocks/Text'

import styles from './OverviewItem.css'

import arrowRightIcon from '/images/icons/arrow-right.raw.svg'

export function OverviewItemInspiration({ title, intro, href, readTime, typeInspiration }) {
  const { __ } = useTranslate()
  const type = typeInspiration && <span>{__`type-${typeInspiration}`}</span>
  const meta = <>{type} {readTime && <ReadTime time={readTime} />}</>
  const dataXSuffix = 'inspiration'

  return <OverviewItemBase {...{ title, intro, href, meta, dataXSuffix }} />
}

export function OverviewItemJob({ title, intro, href, hours }) {
  const { __ } = useTranslate()
  const meta = hours && `${hours} ${__`hours-per-week-hours`}`
  const dataXSuffix = 'job'

  return <OverviewItemBase {...{ title, intro, href, meta, dataXSuffix }} />
}

function OverviewItemBase({ title, intro, href, meta, dataXSuffix }) {
  const { __ } = useTranslate()

  return (
    <article className={styles.componentBase}>
      {title && <HeadingMd h={3} layoutClassName={styles.title}>{title}</HeadingMd>}
      {meta && <div className={styles.meta}>{meta}</div>}
      {intro && <TextMd layoutClassName={styles.intro}>{truncate(intro, 250)}</TextMd>}
      {href && (
        <ButtonIconSmall
          label={__`go-to-item`}
          icon={arrowRightIcon}
          layoutClassName={styles.button}
          dataX={`cta-to-${dataXSuffix}`}
          {...{ href }}
        />
      )}
      {title && (
        <Link
          layoutClassName={cx(styles.link, styles.relativeToParent)}
          dataX={`cta-to-${dataXSuffix}`}
          {...{ href }}
        >
          {title}
        </Link>
      )}
    </article>
  )

  function truncate(str, n) {
    return (str.length > n) ? `${str.substr(0, n - 1)}…` : str
  }
}
