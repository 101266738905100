import { getPagination } from '@kaliber/pagination'
import { useTranslate } from '/machinery/I18n'
import { useQueryString, stringifyQueryString } from '@kaliber/sanity-routing/queryString'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Pagination.css'

import iconChevronLeft from '/images/icons/chevron-left.raw.svg'
import iconChevronRight from '/images/icons/chevron-right.raw.svg'



export function Pagination({ maxPages, currentPage, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const [{ search = '', tags, types }] = useQueryString()

  const pagination = getPagination({
    current: currentPage,
    max: maxPages,
    padding: 1
  })

  return (
    <nav className={cx(styles.component, layoutClassName)}>
      {currentPage > 1 && (
        <a
          href={generateQueryParams({ page: currentPage - 1 })}
          className={cx(styles.item, styles.isPrevious)}
          aria-label={__(currentPage, { page: currentPage - 1 })`pagination-previous-aria-label`}
          data-x='goto-previous-page'
        >
          <Icon icon={iconChevronLeft} />
          <span className={styles.label}>
            {__`pagination-previous`}
          </span>
        </a>
      )}
      {pagination.map((x, i) => x
        ? (
          <a
            key={i}
            href={generateQueryParams({ page: x })}
            className={cx(styles.item, styles.isNumber, currentPage === x && styles.isCurrentPage)}
            aria-label={
              currentPage === x
                ? __(x, { page: x })`pagination-current-page-n`
                : __(x, { page: x })`pagination-page-n`
            }
            data-x={`goto-page-${x}`}
          >
            {x}
          </a>
        )
        : <span className={cx(styles.item, styles.isPadding)} key={i}>…</span>
      )}
      {currentPage < maxPages && (
        <a
          href={generateQueryParams({ page: currentPage + 1 })}
          className={cx(styles.item, styles.isNext)}
          aria-label={__(currentPage, { page: currentPage + 1 })`pagination-next-aria-label`}
          data-x='goto-next-page'
        >
          <Icon icon={iconChevronRight} />
          <span className={styles.label}>
            {__`pagination-next`}
          </span>
        </a>
      )}
    </nav>
  )

  function generateQueryParams({ page }) {
    return `?${stringifyQueryString({ search, tags, types, page })}`
  }
}
